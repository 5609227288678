//借还记录已还清
<template>
  <div class="examRule">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="600px"
    >
      <div class="examRule_head flex">
        <span>合同详情</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="detail_pop">
        <div class="pop-tit">合同信息</div>
        <div class="pop-item">
          <div class="li">
            <span class="color-9">合同信息：</span
            >{{ derails.type == 1 ? "房屋买卖合同" : "房屋租赁合同" }}
          </div>
          <div class="li">
            <span class="color-9">合同编号：</span
            >{{ derails.contract_numbers }}
          </div>
        </div>
        <div class="pop-tit">领取人信息</div>
        <div class="pop-item">
          <div class="li">
            <span class="color-9">区域：</span>{{ derails && derails.city ? derails.city[2] : '' }}
          </div>
          <div class="li">
            <span class="color-9">门店：</span>{{ derails.dian_name }}
          </div>
          <div class="li">
            <span class="color-9">姓名：</span>{{ derails.name }}
          </div>
          <div class="li">
            <span class="color-9">工号：</span>{{ derails.userId }}
          </div>
          <div class="li">
            <span class="color-9">职位：</span
            >{{ getPosition(derails.position) }}
          </div>
        </div>
        <div class="pop-tit">其他信息</div>
        <div class="pop-item">
          <div class="li">
            <span class="color-9">领取时间：</span>{{ derails.createtime }}
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      derails: {}, //合同详情
    };
  },
  methods: {
    //关闭
    close() {
      this.dialogVisible = false;
    },
    //打开
    open(id) {
      this.$axios
        .contractReceiveDetail({
          id,
        })
        .then((res) => {
          this.dialogVisible = true;
          this.derails = res.data;
        });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
}
/deep/ .el-dialog {
  border-radius: 8px;
  overflow: hidden;
}

// .examRule_main {
//   // line-height: 28px;
// }
.detail_pop {
  padding: 20px 30px;
  .pop-tit {
    font-size: 18px;
    margin: 15px 0px 10px 0px;
  }
  .pop-item {
    display: flex;
    font-size: 16px;
    margin: 5px 0px;
    flex-wrap: wrap;
  }
  .pop-item .li {
    width: 50%;
    min-width: 50%;
    max-width: 50%;
    line-height: 32px;
  }
}
</style>
