<template>
  <div class="agree-page">
    <div class="agree_main">
      <!--agree-head start-->
      <div class="agree-head">
        <div class="key_page_select">
          <div class="select_item">
            <div class="label">领取时间:</div>
            <div class="select">
              <el-date-picker
              @change="changeData"
                v-model="from.searchName"
                type="month"
                value-format="yyyy-MM"
                placeholder="选择日期"
              >
              </el-date-picker>
            </div>
          </div>
          <!-- <div class="select_item">
            <div class="label">区域:</div>
            <div class="select">
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div> -->
          <!-- <div class="select_item">
            <div class="label">门店:</div>
            <div class="select">
              <el-select @change="changeData" v-model="from.dian_id" placeholder="请选择" size="160px">
                <el-option
                  v-for="item in shopList"
                  :key="item.id"
                  :label="item.dian_name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div> -->
          <!-- <div class="select_item">
            <div class="label">领取人:</div>
            <div class="select">
              <el-input v-model="input" placeholder="请输入"></el-input>
            </div>
          </div> -->
        </div>
      </div>
      <!--agree-head end-->
      <!--agree-body start-->
      <div class="record-body">
        <div class="record_table">
          <!--record_tr start-->
          <div class="record_tr" v-for="(item) in list" :key="item.id">
            <div class="record_td">
              <div class="td">
                <span class="color-9">合同名称：</span>{{ item.type == 1 ? '房屋买卖合同' : '房屋租赁合同' }}
              </div>
            </div>
            <div class="record_td">
              <div class="td"><span class="color-9">合同编号：</span>{{ item.contract_numbers }}</div>
            </div>
            <div class="record_td">
              <div class="td">
                <span class="color-9">领取时间：</span>{{ item.createtime }}
              </div>
            </div>
            <div class="record_td">
              <div class="agree_a" @click="$refs.detailPop.open(item.id)">
                查看详情>>
              </div>
            </div>
          </div>
          <!--record_tr end-->
        </div>
        <div class="paging" v-if="list.length">
          <el-pagination
            background
            layout="prev, pager, next, jumper, ->, total"
            :total="total"
            @current-change="changePage"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 合同详情弹出 S -->
    <agree-detail-pop ref="detailPop"></agree-detail-pop>
    <!-- 合同详情弹出 E -->
  </div>
</template>

<script>
import AgreeDetailPop from "../../components/AgreeDetailPop";
export default {
  components: { AgreeDetailPop },
  data() {
    return {
      page: 1, //页数
      total: 0, //条数
      list: [], //列表
      options:[],
      value:'',
      input:'',
      from:{
        dian_id:'',
        searchName:'',
        page:1
      },
      shopList:[],//门店列表
    }
  },
  methods:{
    changePage (e) {
      this.from.page = e
      this.getAgreeList()
    },
    changeData () {
      this.init()
    },
    init () {
      this.list = []
      this.from.page = 1
      this.getAgreeList()
    },
    //获取合同列表
    getAgreeList () {
      this.$axios.contractReceive(this.from).then(res=>{
        this.list = res.data.data;
        this.total = res.data.total;
      })
    },
    //获取门店
    getmenDian () {
      this.$axios.menDian().then(res=>{
        this.shopList = res.data
      })
    }
  },
  created () {
    this.getAgreeList()
    this.getmenDian()
  }
};
</script>

<style scoped lang="less">
.key_page_select {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -10px;
  .select_item {
    display: flex;
    font-size: 14px;
    color: #666;
    align-items: center;
    padding: 10px 0px;
    margin: 0 10px;
  }
  .label {
    margin-right: 10px;
    white-space: nowrap;
    width: 80px;
    text-align: right;
  }
  .select {
    width: 200px;
  }
  /deep/ .el-input__inner {
    border: 1px solid #ccc;
    border-radius: 0;
  }
}
.agree_a {
  color: #3273f6;
  cursor: pointer;
}
/deep/.key_page_select .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 200px;
}
.agree_main {
  background: #fff;
  padding-bottom: 30px;
}
.agree-head {
  margin: 0 20px;
  padding: 20px 0px;
  border-bottom: 1px solid #eaeaea;
}
.record_table {
  display: table;
  width: 100%;
  margin: 20px 0px;
}
.record_tr {
  display: table-row;
}
.record_td {
  display: table-cell;
  padding: 15px 30px;
  font-size: 16px;
  line-height: 1.8;
}
.record_td .td {
  line-height: 1.8;
}
.record_tr:nth-child(2n) {
  background: #f7fcff;
}
</style>
